import React, { useState } from 'react';
import TinderCard from 'react-tinder-card'
import SuperLikeForm from './SuperLikeForm'
import ProfileDetail from './ProfileDetail'
import classNames from 'classnames';
import toast from 'react-hot-toast';


const MultiLineBody = ({ body }) => {
  const texts = body?.split('\n')?.map((item, index) => {
    return (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    );
  });
  return <div>{texts}</div>;
};

const LaprasCard = ({ profile }) => {
  const swiped = (dir, nameToDelete) => {
    if (dir === 'right') {
      toast.success(`Liked: ${nameToDelete.name}`);
    }
    if (dir === 'left') {
      toast.error(`Disliked: ${nameToDelete.name}`);
    }

    if (dir === 'up') {
      toast(`SuperLike: ${nameToDelete.name}`, { icon: '🤩' });
      setIsSuperLikeFormOpened(true);
    }
  }

  const outOfFrame = (name, e) => {
    setIsOut(true);
  }

  const [isShowModal, setIsShowModal] = useState(false);
  const [isOut, setIsOut] = useState(false);
  const [isSuperLikeFormOpend, setIsSuperLikeFormOpened] = useState(false);

  return (
    <div>
      <SuperLikeForm
        isSuperLikeFormOpend={isSuperLikeFormOpend}
        setIsSuperLikeFormOpened={setIsSuperLikeFormOpened}
        setIsOut={setIsOut}
      />
      <ProfileDetail
        profile={profile}
        className={classNames(
          isShowModal ? 'animate-scale-up-center' : '',
          isOut ? 'hidden' : '',
        )}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
      />
      <TinderCard
        className={classNames(
          'absolute top-[calc(50%-550px/2)] left-[calc(50%-300px/2)]',
          'rounded-xl z-10',
          'drag-none select-none',
          isOut ? 'hidden' : '',
        )}
        key={profile.name}
        onSwipe={(dir) => swiped(dir, profile)}
        onCardLeftScreen={(e) => outOfFrame(profile, e)}
        preventSwipe={['down']}
        // swipeThreshold={500}
      >
        <div
          id={`card-${profile.name}`}
          className={classNames(
            'w-[300px] h-[550px] bg-gray-100',
            'shadow-lg rounded-xl flex flex-col items-center',
          )
          }
        >
          <button
            className={classNames(
              'w-full h-full rounded-t-xl outline-none ',
              "pressable",
            )}
            onClick={() => setIsShowModal(true)}
          >
            <img
              className={classNames(
                "pressable",
                "rounded-t-xl w-full h-full object-cover",
                "drag-none outline-none",
              )}
              src={profile.url}
              alt={profile.name}
            />
          </button>
          <div className="mt-6">
            <p className='w-full mb-6 text-xl' >
              {profile.name}
            </p>
            <div
              className='w-full h-[100px] mb-6 text-sm inline-block'
            >
              <MultiLineBody body={profile.description} />
            </div>
          </div>
        </div>
      </TinderCard>
    </div>
  )
}

export default LaprasCard
