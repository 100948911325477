// import React, { useState } from 'react';
import LaprasCard from './LaprasCard'
// import NextProfileCard from './NextProfileCard'
// import OutOfExampleCard from './OutOfExampleCard'
import { db } from '../db'

const LaprasSelection = () => {
  const profiles = db

  return (
    <>
      {
        profiles.map((profile) => {
          return (
            <LaprasCard
              key={profile.name}
              profile={profile}
            />
          )
        })
      }
    </>
  )
}

export default LaprasSelection;
