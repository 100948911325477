const Header = () => {
  return (
      <div className="flex justify-center pt-16">
        <h1 className="text-4xl text-white font-semibold">
          Tinderapras
        </h1>
      </div>
  )
}
export default Header;
