const randomScore = () => parseFloat((Math.random(1)*5).toFixed(1))

export const db = [
  {
    name: "YumaFuu",
    url: "https://github.com/yumafuu.png",
    description: `職種　：サーバーサイドエンジニア
会社　：株式会社ispec
職歴記載あり`,
    score: {
      tech: { name: "技術力", value: randomScore() },
      business: { name: "ビジネス", value: randomScore() },
      influence: { name: "影響力", value: randomScore() },
    },
    wantToDo: `やりたいこと

[やりたい事]
AWSやGCP等のパブリッククラウドのインフラ構築

開発チームの開発基盤の構築や整備

IaCを使ったインフラのコード化

新技術を積極的にキャッチアップする力を活かした新規サービス立ち上げ・既存サービスのリプレイスおよび技術選定

[勤務地]
転居可否：不可
現住所からの通勤を考えております。
大阪府以外の場合は原則フルリモートワークで月1回程度出社可能`
  },
  {
    name: "yamad07",
    url: "https://github.com/yamad07.png",
    score: {
      tech: { name: "技術力", value: randomScore() },
      business: { name: "ビジネス", value: randomScore() },
      influence: { name: "影響力", value: randomScore() },
    },
    description: `職種　：プロジェクトマネージャー
会社　：株式会社ビッ○モーター
職歴記載あり`,
    wantToDo: `エンジニアとして職務範囲を限定するのではなく、
サービス品質の向上、プロダクトの成長を軸にフルスタックに活躍を目指しており、
事業貢献にコミットしていきたいと考えております。

`,
  },
  {
    name: "hori-design",
    url: "https://github.com/hori-design.png",
    score: {
      tech: { name: "技術力", value: randomScore() },
      business: { name: "ビジネス", value: randomScore() },
      influence: { name: "影響力", value: randomScore() },
    },
    description: `居住地：Gif
職種　：ソフトウェアエンジニア
会社　：株式会社ホリデザイン
OSSシルバーコントリビューター
職歴記載あり`,
    wantToDo: `
小中規模のプロダクトをゴリゴリ開発していきたいです。
`,
  },
  {
    name: "Kamiharari",
    url: "https://pbs.twimg.com/profile_images/1026443150972280838/nIivtTm9_400x400.jpg",
    score: {
      tech: { name: "技術力", value: randomScore() },
      business: { name: "ビジネス", value: randomScore() },
      influence: { name: "影響力", value: randomScore() },
    },
    description: `居住地：松本
職種　：ソフトウェアエンジニア
会社　：松本市役所
職歴記載あり`,
    wantToDo: ` かみはらえみです。
石橋は金槌で殴り倒しながら渡るタイプ。中リスナー。写真と製本。呟きは、呟き。生産性とかないのです。
    `,
  },
]
