import classNames from 'classnames'
import Header from './components/Header'
import LaprasSelection from './components/LaprasSelection'
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div
      className={ classNames(
        "w-screen h-screen",
        "bg-gradient-to-bl from-[#FF7557] to-[#FC2B7A]",
      )}
    >
      <Header />
      <LaprasSelection />
      <Toaster/>
    </div>
  );
}

export default App;
