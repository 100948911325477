import classNames from 'classnames';
import Modal from 'react-modal';


const ProfileDetail = ({
  profile,
  isShowModal,
  setIsShowModal,
}) => {
  Modal.setAppElement('#root');

  return (
    <Modal
      isOpen={isShowModal}
      onRequestClose={() => setIsShowModal(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 20,
          overflow: 'auto',
        },
      }}
      className={classNames(
        'rounded-xl shadow-2xl my-20 p-4',
        'w-[90%] mx-auto bg-white',
        'focus:outline-none',
        'max-w-md',
        isShowModal ? 'animate-scale-up-center' : '',
      )}
    >
      <div
        className={classNames(
          // 'bg-white z-20 rounded-xl shadow-2xl mt-20 p-4',
          // 'max-w-md ',
          isShowModal ? 'block' : 'hidden',
        )}
        id={`detail-${profile.name}`}
      >

        <div className="rounded-lg mt-4 px-1">
          <div className="flex justify-center">
            <img
              className="rounded-full w-32 h-32 mb-5"
              src={profile.url}
              alt={profile.name}
            />
          </div>
          <p className="text-2xl font-bold text-center">
            {profile.name}
          </p>

          <div className="mt-5">
            <p className="font-bold">
              スコア評価
            </p>
            {
              Object.keys(profile.score).map((key) => {
                const { name, value } = profile.score[key];
                const width = value/5.0 * 100 + '%';

                return (
                  <div className="mt-4" key={key}>
                    <dl className="flex items-center justify-between">
                      <dt className="font-tin text-sm font-medium">{name}</dt>
                      <dd className="text-lg">{value}</dd>
                    </dl>
                    <div
                      className={classNames(
                        "relative flex h-1 w-full overflow-hidden rounded-full",
                        "bg-gray-200",
                      )}
                    >
                      <div
                        role="progressbar"
                        aria-valuenow="35"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width }}
                        className={classNames(
                          "flex h-full items-center justify-center text-xs",
                          value < 4 ? 'bg-blue-500' : value <= 4.5 ? 'bg-yellow-500' : 'bg-red-500',
                        )}
                      ></div>
                    </div>
                  </div>
                )
              })
            }

          </div>

          <div className="mt-5">
            <p className="font-bold">
              やりたいこと
            </p>
            <div className="rounded-lg bg-gray-100 p-4 mt-2">
              <p className="text-sm">
                {profile.wantToDo}
              </p>
            </div>
          </div>

          <div>
            <p className="font-bold mt-5">
              スカウトに求める条件
            </p>
            <div className="">
              <div className="mt-2">
                <p className="my-2">
                  <span className="inline-block w-[100px] font-bold">年収</span>
                  <span>{`${(Math.random(1)*1000).toFixed(0)}万円`}</span>
                </p>
                <hr className="mt-1" />
                <p className="my-2">
                  <span className="inline-block w-[100px] font-bold">働き方</span>
                  <span>{'リモート'}</span>
                </p>
                <hr className="my-1" />
                <p className="mt-2">
                  <span className="inline-block w-[100px] font-bold">雇用形態</span>
                  <span>{'社員'}</span>
                </p>
                <hr className="mt-1" />
                <p className="my-2">
                  <span className="inline-block w-[100px] font-bold">業態</span>
                  <span>{'自社開発'}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
 )
}

export default ProfileDetail;
