import classNames from 'classnames';

const SuperLikeForm = ({
  isSuperLikeFormOpend,
  setIsSuperLikeFormOpened,
  setIsOut,
}) => {
  return (
    <div
      className={classNames(
        "absolute top-0 left-0 w-full h-full z-20",
        isSuperLikeFormOpend ? 'bg-black bg-opacity-70' : 'hidden',
      )}
    >
      <div
        className={classNames(
          'relative bg-white z-20 rounded-xl shadow-2xl top-[20%] p-4',
          'max-w-md mx-auto',
          isSuperLikeFormOpend ? '' : 'hidden',
          isSuperLikeFormOpend ? 'animate-bounce-top' : '',
        )}
      >
        <div className="mx-auto max-w-md">
          <div className="mt-10">
            <label className="mb-1 block text-xl font-medium text-gray-700">
              SuperLikeした理由を教えてください🤩🤩
            </label>
            <textarea
              id="memo"
              className={classNames(
                "outline p-2 mt-12 resize-none",
                "block w-full rounded-md border-gray-300 shadow-sm",
                "focus:border-rose-300 focus:ring focus:ring-opacity-50",
                "disabled:cursor-not-allowed disabled:bg-gray-50",
              )}
              rows="5"
              placeholder="メモ"
            >
            </textarea>
            <button
              className={classNames(
                "border border-gray-300",
                "mt-20 w-full bg-primary-500 hover:bg-primary-700 font-bold py-2 px-4 rounded",
                "focus:outline-none focus:shadow-outline",
                "hover:bg-gray-100 focus:ring focus:ring-gray-100 ",
              )}

              onClick={() => {
                setIsSuperLikeFormOpened(false)
                setIsOut(true)
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SuperLikeForm;
